<template>
  <div class="app-container" v-loading="loading">
    <el-header class="new-header">
      <div class="new-header-left">用户列表</div>
      <div class="new-header-right">
        <el-button
          class="btn"
          @click="dialogForAddUser = true"
          type="primary"
          plain
          >新建用户</el-button
        >
      </div>
    </el-header>
    <el-form
      :inline="true"
      :model="searchForm"
      ref="searchForm"
      @keyup.enter.native="onSubmit"
    >
      <el-form-item label="用户手机号">
        <el-input
          v-model="searchForm.phone"
          placeholder="用户手机号"
          size="small"
          clearable
          @clear="searchForm.phone = null"
        ></el-input>
      </el-form-item>
      <el-form-item label="用户姓名:" prop="userName">
        <el-input
          v-model="searchForm.userName"
          placeholder="用户姓名"
          size="small"
          clearable
          @clear="searchForm.userName = null"
        ></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="state">
        <el-select
          v-model="searchForm.state"
          clearable
          @clear="searchForm.state = null"
          placeholder="状态"
          size="small"
        >
          <el-option label="启用" :value="1"> </el-option>
          <el-option label="停用" :value="0"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit" icon="search" size="small"
          >搜索</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="list"
      border
      style="width: 100%"
      ref="list"
      stripe
      size="small"
    >
      <el-table-column
        prop="userName"
        label="用户姓名"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="phone"
        label="用户手机号"
        align="center"
      ></el-table-column>

      <el-table-column
        prop="adminName"
        label="创建人名称"
        align="center"
      ></el-table-column>

      <el-table-column
        prop="createTime"
        label="创建时间"
        align="center"
      ></el-table-column>
      <el-table-column prop="stateName" label="状态" align="center" width="200">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.state"
            active-text="启用"
            inactive-text="停用"
            :active-value="1"
            :inactive-value="0"
            @change="handleChangeUserState(scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-tooltip placement="top" content="编辑">
            <el-button
              type="text"
              @click="handleInitModifyUser(scope.row)"
              size="small"
            >
              编辑
            </el-button>
          </el-tooltip>
          <el-tooltip placement="top" content="删除">
            <el-button
              type="text"
              style="color: red"
              @click="handleDeleteUser(scope.row)"
              size="small"
            >
              删除
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageVo.current"
        :page-sizes="[2, 10, 20, 50, 100]"
        :page-size="pageVo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
      >
      </el-pagination>
    </div>
    <el-dialog title="新建用户" :visible.sync="dialogForAddUser">
      <el-form
        ref="addForm"
        :model="addForm"
        label-width="100px"
        :rules="rules"
      >
        <el-row type="flex" justify="center">
          <el-col :span="12">
            <el-form-item label="手机号码:" prop="phone">
              <el-input
                v-model="addForm.phone"
                placeholder="手机号码"
              ></el-input>
            </el-form-item>
            <el-form-item label="用户姓名:" prop="userName">
              <el-input
                v-model="addForm.userName"
                placeholder="请输入用户姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="密码:" prop="password">
              <el-input
                v-model="addForm.password"
                placeholder="密码,如果不填,默认为手机号"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="info" @click="dialogForAddUser = false"
          >取消</el-button
        >
        <el-button type="primary" @click="handleAddUser">确认</el-button>
      </div>
    </el-dialog>
    <el-dialog title="编辑用户" :visible.sync="dialogForModifyUser">
      <el-form
        ref="modifyForm"
        :model="modifyForm"
        label-width="100px"
        :rules="rules"
      >
        <el-row type="flex" justify="center">
          <el-col :span="12">
            <el-form-item label="手机号码:" prop="phone">
              <el-input
                v-model="modifyForm.phone"
                placeholder="手机号码"
              ></el-input>
            </el-form-item>
            <el-form-item label="用户姓名:" prop="userName">
              <el-input
                v-model="modifyForm.userName"
                placeholder="请输入用户姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="密码:" prop="password">
              <el-input
                v-model="modifyForm.password"
                placeholder="密码,如果不填,默认为手机号"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="info" @click="dialogForModifyUser = false"
          >取消</el-button
        >
        <el-button type="primary" @click="handleModifyUser">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  createUser,
  deleteUser,
  editUser,
  editUserState,
  getUserListByPage,
} from "../../api";

export default {
  name: "",
  data() {
    return {
      dialogForAddUser: false,
      dialogForModifyUser: false,
      searchForm: {
        adminId: this.$store.getters.adminId,
        userName: null,
        state: null,
        phone: null,
      },
      addForm: {
        userName: "",
        password: "",
        phone: "",
        adminId: this.$store.getters.adminId,
      },
      modifyForm: {
        userName: "",
        password: "",
        phone: "",
        adminId: this.$store.getters.adminId,
      },
      list: [],
      pageVo: {
        current: 1,
        size: 10,
      },
      totalCount: 0,
      loading: false,
      rules: {
        phone: [
          { required: true, message: "请输入用户手机号码", trigger: "blur" },
        ],
        userName: [
          { required: true, message: "请输入用户姓名", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.loadUserList();
  },
  methods: {
    handleDeleteUser(row) {
      if (row.userId) {
        this.$confirm(`确定删除用户${row.userName}吗？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          deleteUser({
            userId: row.userId,
            adminId: this.$store.getters.adminId,
          }).then((data) => {
            if (data) {
              this.$notify({
                type: "success",
                title: "成功",
                message: "删除成功",
              });
              this.loadUserList();
            }
          });
        });
      }
    },
    handleChangeUserState(row) {
      let param = {
        userId: row.userId,
        state: row.state,
        adminId: this.$store.getters.adminId,
      };
      editUserState(param).then((data) => {
        if (data) {
          let message =
            row.state === 1
              ? `启用用户${row.userName}成功`
              : `停用用户${row.userName}成功`;
          this.$notify({
            type: "success",
            title: "成功",
            message: message,
          });
        }
      });
    },
    handleAddUser() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          let param = {
            ...this.addForm,
          };
          createUser(param).then((data) => {
            if (data) {
              this.$notify({
                type: "success",
                title: "成功",
                message: "创建用户成功",
              });
              this.dialogForAddUser = false;
              this.loadUserList();
            }
          });
        }
      });
    },
    handleInitModifyUser(row) {
      Object.assign(this.modifyForm, row);
      this.modifyForm.password = null
      this.dialogForModifyUser = true;
    },
    handleModifyUser() {
      this.$refs.modifyForm.validate((valid) => {
        if (valid) {
          let param = {
            ...this.modifyForm,
          };
          editUser(param).then((data) => {
            if (data) {
              this.$notify({
                type: "success",
                title: "成功",
                message: "编辑用户成功",
              });
              this.dialogForModifyUser = false;
              this.loadUserList();
            }
          });
        }
      });
    },
    onSubmit() {
      this.pageVo.current = 1;
      this.loadUserList();
    },
    handleSizeChange(val) {
      this.pageVo.size = val;
      this.loadUserList();
    },
    handleCurrentChange(val) {
      this.pageVo.current = val;
      this.loadUserList();
    },
    loadUserList() {
      let params = {
        ...this.pageVo,
        params: this.searchForm,
      };
      this.loading = true;
      getUserListByPage(params).then((data) => {
        console.log(data);
        this.list = data.records;
        this.totalCount = data.total;
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="scss"></style>
